var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasUser
    ? _c(
        "b-dropdown",
        { attrs: { position: "is-bottom-left" } },
        [
          _c(
            "a",
            {
              staticClass: "navbar-item button is-transparent",
              attrs: { slot: "trigger" },
              slot: "trigger",
            },
            [
              _c("b-icon", {
                staticClass: "user",
                attrs: { pack: "fas", icon: "user", size: "is-small" },
              }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.userName))]),
              _vm._v(" "),
              _c("b-icon", {
                attrs: { pack: "fas", icon: "angle-down", size: "is-small" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.userData
            ? [
                _c("b-dropdown-item", { attrs: { "has-link": "" } }, [
                  _c(
                    "a",
                    { attrs: { href: "/clan/" + _vm.userData.clan.slug } },
                    [_vm._v(_vm._s(_vm.userData.clan.name))]
                  ),
                ]),
                _vm._v(" "),
                _c("b-dropdown-item", { attrs: { "has-link": "" } }, [
                  _c("a", { attrs: { href: "/player/" + _vm.userSlug } }, [
                    _vm._v("Your profile"),
                  ]),
                ]),
                _vm._v(" "),
                _c("b-dropdown-item", { attrs: { separator: "" } }),
                _vm._v(" "),
                _c("b-dropdown-item", { attrs: { "has-link": "" } }, [
                  _c("a", { on: { click: _vm.removeUser } }, [
                    _vm._v("Forget Me"),
                  ]),
                ]),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }