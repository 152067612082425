var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar-end" },
    [
      _c("changelog"),
      _vm._v(" "),
      _c("div", { staticClass: "navbar-item nav-search-item" }, [
        _c(
          "div",
          { staticClass: "field is-expanded" },
          [
            _c("search-box", {
              attrs: { "selected-clan": _vm.selectedClan },
              on: {
                "update:selectedClan": function ($event) {
                  _vm.selectedClan = $event
                },
                "update:selected-clan": function ($event) {
                  _vm.selectedClan = $event
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("user"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }