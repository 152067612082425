var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-autocomplete", {
    attrs: {
      placeholder: "Search by name or tag",
      field: "tag",
      size: _vm.size,
      icon: "search",
      "icon-pack": "fa",
      "keep-first": "",
      expanded: "",
      data: _vm.data,
      loading: _vm.isLoading,
    },
    on: {
      input: _vm.fetchData,
      select: function (option) {
        return this$1.$emit("update:selectedClan", option ? option : null)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (props) {
          return [
            _c("div", { staticClass: "media" }, [
              _c("div", { staticClass: "media-left" }, [
                _c("img", {
                  attrs: { width: "32", src: props.option.badgeUrls.small },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "media-content" }, [
                _c("strong", [_vm._v(_vm._s(props.option.name))]),
                _vm._v(" "),
                _c("small", [
                  _c("i", { staticClass: "fa fa-tag" }),
                  _vm._v(" " + _vm._s(props.option.tag) + " "),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("small", [
                  _c("i", { staticClass: "fa fa-users" }),
                  _vm._v(" " + _vm._s(props.option.members) + " members "),
                ]),
              ]),
            ]),
          ]
        },
      },
    ]),
    model: {
      value: _vm.tag,
      callback: function ($$v) {
        _vm.tag = $$v
      },
      expression: "tag",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }